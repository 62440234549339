import React from 'react'

import { Container } from 'react-bootstrap';

export default function MisFinanzas() {

  return (
    <Container>
        <h1>Finanzas</h1>
    </Container>
  )
}

