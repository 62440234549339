import React from 'react'

import { Container } from 'react-bootstrap';

export default function NoAutorizado() {
  
  return (
    <Container>
        <h1>Usted no esta autorizado para acceder a esta pagina</h1>
    </Container>
  )
}