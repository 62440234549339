import React from 'react'

import '../style/loadingspinner.css';

export default function LoadingSpinner() {

  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  )

}
