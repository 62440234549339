import React from 'react';
import Banner from '../components/Banner';
import Servicios from '../components/Servicios';

export default function Home() {

  return (
    <>
        <Banner />
        <Servicios />
    </>
  )
}
